import React, { FunctionComponent } from "react";
import { ImageInterface } from "../../types/SanityTypes";
import HeroSliderBlock from "../HeroSliderBlock";
import "./styles.scss";

export interface HeroBannerSliderInterface {
  name: string;
  preferPerformance: boolean;
  content: {
    heading: string;
    headingQuotes?: boolean;
    description?: string;
    _rawFeaturePromoImage?: ImageInterface;
    ctaLabel?: string;
    ctaLink?: {
      slug?: {
        current: string;
      };
    };
    bgColor: {
      title: string;
      value: string;
    };
    contentAlignment?: string;
  }[];
}

const HeroBannerSlider: FunctionComponent<HeroBannerSliderInterface> = ({ content, preferPerformance }) => {
  return (
    <section className="heroBannerSlider" data-testid="hero-banner-slider">
      {content && <HeroSliderBlock data={content} type="hero-banner-slider" preferPerformance={preferPerformance} />}
    </section>
  );
};

export default HeroBannerSlider;
