import React, { FunctionComponent, useContext } from "react";
import { RightTriangleArrow } from "../../images/icons/triangleRight";
import { LeftTriangleArrow } from "../../images/icons/triangleLeft";
import { ImageInterface } from "../../types/SanityTypes";
import Accessibility from "../Accessibility";
import "./styles.scss";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { event66 } from "../../analytics/event66";
import HeroBanner from "../HeroBanner";
import Carousel from "react-multi-carousel-jsm";

interface HeroBannerSliderInterface {
  heading: string;
  headingQuotes?: boolean;
  description?: string;
  _rawFeaturePromoImage?: ImageInterface;
  ctaLabel?: string;
  ctaLink?: {
    slug?: {
      current: string;
    };
  };
  bgColor: {
    title: string;
    value: string;
  };
  contentAlignment?: string;
}

type SliderContentUnionType = HeroBannerSliderInterface[];

export interface SliderBlockInterface {
  data: SliderContentUnionType;
  textColor?: string;
  type: string;
  variation?: string;
  _rawImage?: ImageInterface;
  url?: string;
  title?: string;
  preferPerformance?: boolean;
}

const HeroSliderBlock: FunctionComponent<SliderBlockInterface> = ({ data, type, preferPerformance=false }) => {
  const { sanityAccessibility } = useContext(LocalizedContext);
  const { previous, next } = sanityAccessibility || {};

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 0 },
      items: 1
    }
  };

  const renderSlides = () => {
    if (type === "hero-banner-slider") {
      return (data as HeroBannerSliderInterface[]).map((feature: HeroBannerSliderInterface, index: number) => {
        return (
          <React.Fragment key={`${index}`}>
              <HeroBanner {...feature} preferPerformance={index ? false : preferPerformance} tabIndex={0} />
          </React.Fragment>
        );
      });
    }
  };

  const CustomRightArrow = () => {
    return <button className="react-multi-carousel__arrow react-multi-carousel__arrow--right" onClick={() => event66(next ? next : "slider next")} tabIndex={0}>
      <RightTriangleArrow />
      <Accessibility location={next}/>
    </button>;
  };

  const CustomLeftArrow = () => {
    return <button className="react-multi-carousel__arrow react-multi-carousel__arrow--left" onClick={() => event66(previous ? previous : "slider next")} tabIndex={0}>
      <LeftTriangleArrow />
      <Accessibility location={previous}/>
    </button>;
  };

  return (
    <div
      className="hero-slider-block"
      data-testid="hero-slider-block"
    >
      <div className="slider-size">
        <div data-testid="swiper">
          <Carousel
            showDots={true}
            infinite={true}
            responsive={responsive}
            customRightArrow={CustomRightArrow()}
            customLeftArrow={CustomLeftArrow()}
            itemClass="hero-slider-block-item"
          >
            {renderSlides()}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
export default HeroSliderBlock;
